/* Fallback */
html {
  color: #2a2a2a; /* --color-mpr-charcoal */
  background-color: #f1f2f2; /* --color-bg-gray */
}

/* Variables */
body {
  --color-mpr-blue: #4298b5;
  --color-mpr-blue-trans: rgba(66, 152, 181, 0.5);
  --color-mpr-blue-trans-bg: rgba(66, 152, 181, 0.1);
  --color-mpr-green: #2eba80;
  --color-mpr-green-light: #9ee6C9;
  --color-mpr-green-med: #29a370; 
  /* ^ just dark enough for white large text to have enough contrast */
  --color-mpr-green-dark: #259466;
  --color-mpr-green-hover: #30d993;
  --color-mpr-yellow: #f9a909;
  --color-mpr-red: #eb5c40;
  --color-mpr-red-dark: #C74E36;
  --color-mpr-red-hover: #ee5236;
  --color-mpr-pink: #fc6d80;

  --color-bg-gray: #f1f2f2;
  --color-mpr-light-gray: #d3d2d0;
  --color-mpr-dark-gray: #757575;
  --color-mpr-charcoal: #2a2a2a;
  --color-mpr-charcoal-trans: rgb(42, 42, 42, 0.8);
  --color-mpr-button-blue: #327e98;
  --color-mpr-button-blue-dark: #205060;

  --color-black: #000; /* or just use `white` or `#fff` */
  --color-black-trans: rgba(0, 0, 0, 0.2);
  --color-white: #fff; /* or just use `white` or `#fff` */
  --color-white-trans: rgba(255, 255, 255, 0.8);

  --subtle-shadow: 0 .25rem 0.625rem 0 #0000000D;
}
