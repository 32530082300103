.modal {
  align-items: center;
  background-color: var(--color-black-trans);
  display: flex;
  justify-content: center;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: var(--subtle-shadow);
}

.modal_close {
  display: none;
}

.modal > div {
  color: var(--color-white);
  border-radius: 0.5rem;
  box-sizing: border-box;
  max-height: 100%;
  max-width: 70%;
  /* overflow-y: auto; */
  padding: 3rem;
  width: max-content;
}

.modal_header {
  font-size: var(--type-size-mega);
  display: flex;
  justify-content: space-between;
}

.btn_close {
  display: flex;
  background-color: transparent;
  border: none;
}

@media (max-width: 90em) {
  .modal > div {
    max-width: 95%;
    padding: 2.5rem 2rem;
  }
}

@media (max-width: 65em) {
  .modal > div {
    max-width: 100vw;
    padding: 2.5rem 2rem;
  }

  /* .modal_square > div {
    border-radius: unset;
  } */
}

@media (max-width: 36em) {
  .modal > div {
    border-radius: 0.5rem;
    padding: 1.7rem 2rem;
  }
}
