input {
  border-radius: 0.375rem;
  border: 0.063rem solid var(--color-mpr-dark-gray);
  padding: 1rem;
  max-width: 100%;
}

.slim-input, .has-slim-inputs input {
  padding: .628rem .875rem;
}

.full-width-input, .has-full-width-inputs input {
  width: 100%;
}

input::placeholder {
  color: var(--color-mpr-dark-gray);
  opacity: 1;
}

label {
  font-weight: 600;
}

label input {
  font-weight: 400;
}

.label-text {
  margin-bottom: 0.75rem;
}

.input-container {
  margin-bottom: 1.625rem;
}

input[type="checkbox"] {
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
}

input[type="checkbox"]:checked {
  background-color: var(--color-mpr-green);
  border-color: var(--color-mpr-green);
  background-image: url("/img/checkmark.svg");
  background-position: center;
  background-repeat: no-repeat;
}

input[type="file"] {
  cursor: pointer;
}

input[type="file"]::file-selector-button {
  color: var(--color-mpr-button-blue);
  padding: 0.5rem 0.75rem;
  border: 1px var(--color-mpr-dark-gray) solid;
  background-color: transparent;
  border-radius: 0.375rem;
  text-transform: uppercase;
  font-weight: 600;
  transition: background-color .15s;
}

input[type="file"]::file-selector-button:hover, 
input[type="file"]::file-selector-button:focus {
  background-color: var(--color-bg-gray);
  color: var(--color-mpr-button-blue-dark);
}

input[disabled] {
  border: none;
}
