/* Fallback */
html,
#gsearch .gsc-control-cse {
  font-family: 'Noto Sans', system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.125;
  font-weight: normal;
  letter-spacing: normal;
  text-wrap: pretty;
}

/* Variables */
body {
  /* font sizes scale linearly between 25–75rem */
  /* to recalculate: use clamp.font-size.app and change vw to vmin */

  /* microcopy — captions, related links, sidebar text: 13/15 – 14/16px */
  --font-size-sm: clamp(0.8125rem, 0.7813rem + 0.125vmin, 0.875rem);
  --line-height-sm: clamp(0.9375rem, 0.9063rem + 0.125vmin, 1rem);
  /* body text, user copy h4+: 15/17 – 16/18px */
  --font-size-md: clamp(0.9375rem, 0.9063rem + 0.125vmin, 1rem);
  --line-height-md: clamp(1.0625rem, 1.0313rem + 0.125vmin, 1.125rem);
  /* teaser headlines, user copy h3, buttons: 17/19 – 18/20px */
  --font-size-lg: clamp(1.0625rem, 1.0313rem + 0.125vmin, 1.125rem);
  --line-height-lg: clamp(1.1875rem, 1.1563rem + 0.125vmin, 1.25rem);
  /* category headings, user copy h2, slider headlines: 20/22 – 22/24px */
  --font-size-xl: clamp(1.25rem, 1.1875rem + 0.25vmin, 1.375rem);
  --line-height-xl: clamp(1.375rem, 1.3125rem + 0.25vmin, 1.5rem);
  /* sidebar headings, calls to action: 22/24 – 26/28px */
  --font-size-2x: clamp(1.375rem, 1.25rem + 0.5vmin, 1.625rem);
  --line-height-2x: clamp(1.5rem, 1.375rem + 0.5vmin, 1.75rem);
  /* page names, primary headlines: 24/26 – 34/36px */
  --font-size-3x: clamp(1.5rem, 1.1875rem + 1.25vmin, 2.125rem); 
  --line-height-3x: clamp(1.625rem, 1.3125rem + 1.25vmin, 2.25rem); 

  font-family: 'Noto Sans', system-ui, sans-serif;
  font-size: var(--font-size-md);
  line-height: calc(1em + 0.125rem);
  font-weight: 400;
}

/* Core styles */
h1,
.hdg-1,
.type-3x {
  font-size: var(--font-size-3x);
  line-height: var(--line-height-3x);
}
h2,
.hdg-2,
.type-2x {
  font-size: var(--font-size-2x);
  line-height: var(--line-height-2x);
}
h3,
.hdg-3,
.userContent h2,
.type-xl {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
}
h4,
.hdg-4,
.userContent h3,
.type-lg {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
}
h5,
h6,
p,
li,
.hdg-5,
.hdg-6,
.userContent h4,
.userContent h5,
.userContent h6,
.type-md {
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
}
small,
.hdg-small,
.type-sm {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b,
.hdg,
.gs-title {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6,
.btn,
.hdg {
  margin: 0;
}

.userContent h2,
.userContent h3,
.userContent h4,
.userContent h5,
.userContent h6 {
  margin: 0 0 0.375rem 0;
}

.type-balance {
  text-wrap: balance;
}

p {
  margin: 0;
}
p + * {
  margin-top: calc(1em + 0.125rem);
  /* i.e., the current line-height, which equals font-size plus 2px */
}

/* Specific text styles */
.hdg-block {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
  background-color: var(--color-mpr-charcoal);
  color: var(--color-white);
  border-radius: 0.188rem 0.188rem 0 0;
  text-transform: uppercase;
  text-align: center;
  padding: 1.125rem;
  margin: 0;
}

.hdg-block-slim {
  width: fit-content;
  padding: 0.625rem 0.5rem;
  border-radius: .188rem;
}

.hdg-block-blue {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
  background-color: var(--color-mpr-button-blue);
  color: var(--color-white);
  border-radius: 0.188rem 0.188rem 0 0;
  text-transform: uppercase;
  text-align: center;
  padding: 1.125rem;
  margin: 0;
  cursor: pointer;
}

.hdg-block-blue:hover,
.hdg-block-blue:focus {
  border-color: var(--color-mpr-button-blue-dark);
  background: var(--color-mpr-button-blue-dark);
  color: var(--color-white);
}

.hdg-section {
  color: var(--color-white);
  background-color: var(--color-mpr-green-med);
  padding: var(--line-height-md) var(--gutter);
  margin: var(--gutter) calc(-1 * var(--gutter));
  text-align: center;
  position: relative;
}
@media (min-width: 48em) {
  .hdg-section {
    margin-top: 0;
    text-align: left;
  }
}

.hdg-section-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (min-width: 48em) {
  .hdg-section-text {
    flex-direction: row;
    justify-content: space-between;
    padding-left: var(--gutter);
  }
}

.category-link {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
  color: var(--color-mpr-button-blue);
  font-weight: 400;
  margin-bottom: .5rem;
  display: block;
  text-decoration: underline;
}

.category-link:hover,
.category-link:focus {
  color: var(--color-mpr-button-blue-dark);
  text-decoration: none;
}

.category-link-dark-bg {
  color: var(--color-mpr-yellow);
}

.category-link-dark-bg:hover,
.category-link-dark-bg:focus {
  color: #fff;
}

.category-link svg {
  margin-right: .25rem;
}


/* utility styles */
.type-caps {
  text-transform: uppercase;
}
.type-center {
  text-align: center;
}
.type-right {
  text-align: right;
}
.type-mt {
  margin-top: calc(1em + 0.125rem);
  /* i.e., the current line-height, which equals font-size plus 2px */
}
.type-mb {
  margin-bottom: calc(1em + 0.125rem);
  /* i.e., the current line-height, which equals font-size plus 2px */
}
.type-minisymbol { /* e.g. for copyrights and trademarks */
  vertical-align: top;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 500;
}
.type-slug {
  display: inline-block;
  vertical-align: bottom;
  color: #fff;
  background-color: var(--color-mpr-red-dark);
  border: 1px #fff solid;
  border-radius: 0.063rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.25rem;
  font-size: var(--font-size-sm);
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
}

ul {
  list-style: none;
}
ul,
ol {
  margin: 0;
  padding: 0;
}
.bullet-list {
  list-style: disc;
  margin: 1rem 0 0 2rem;
}

a {
  color: inherit;
  text-decoration-color: transparent;
}

.story .story-body a:not(.btn),
.userContent a:not(.btn),
.basic-link,
.has-basic-links a,
.sidebar a,
.election-hub p a {
  color: var(--color-mpr-button-blue);
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: var(--color-mpr-blue-trans);
  text-underline-offset: 0.15em;
  cursor: pointer;
}

.story .story-body a:not(.btn):active,
.story .story-body a:not(.btn):focus,
.story .story-body a:not(.btn):hover,
.userContent a:not(.btn):active,
.userContent a:not(.btn):focus,
.userContent a:not(.btn):hover,
.basic-link:active,
.basic-link:focus,
.basic-link:focus-within,
.basic-link:hover,
.has-basic-links a:active,
.has-basic-links a:focus,
.has-basic-links a:hover,
.sidebar a:active,
.sidebar a:focus,
.sidebar a:hover,
.election-hub p a:active,
.election-hub p a:focus,
.election-hub p a:hover {
  color: var(--color-mpr-button-blue-dark);
  text-decoration-color: transparent;
}

.date {
  color: var(--color-mpr-dark-gray);
  margin-bottom: 0.5rem;
}

@media (max-width: 48em) {
  .btn-full-width-on-mobile {
    display: flex;
    width: 100%;
  }
}
