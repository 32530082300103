.header {
  background-color: var(--color-mpr-charcoal);
  color: var(--color-white);
  font-weight: 600;
}

.header-top {
  z-index: 1;
  position: relative;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-logo {
  height: 3rem;
}

.header-tagline {
  display: none;
  margin-top: 0.375rem;
}

.header-ctas {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  justify-content: center;
}

.header-ctas .btn-newsletters {
  border-color: transparent;
  text-transform: none;
}

.header-ctas .btn-newsletters svg {
  height: 1.25rem;
  width: auto;
  stroke: transparent;
  fill: var(--color-mpr-light-gray);
  transition: all 0.2s;
}

.header-ctas .btn-newsletters:active,
.header-ctas .btn-newsletters:focus,
.header-ctas .btn-newsletters:hover {
  background-color: var(--color-mpr-charcoal);
  color: var(--color-mpr-yellow);
}

.header-ctas .btn-newsletters:active svg,
.header-ctas .btn-newsletters:focus svg,
.header-ctas .btn-newsletters:hover svg {
  fill: var(--color-mpr-yellow);
}

@media screen and (max-width: 37rem) {
  .header-ctas {
    gap: 0.5rem;
  }
  .header-ctas .btn {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
  }
  .header-ctas .icon-mail,
  .header-ctas .icon-heart {
    display: none;
  }
}

@media screen and (max-width: 23rem) {
  .header-ctas {
    flex-direction: column-reverse;
    gap: .125rem;
  }

  .header-ctas .btn {
    padding: .375rem .5rem;
  }
}

.header-nav {
  padding-bottom: 1rem;
}

.header-nav ul {
  display: flex;
  justify-content: space-between;
}

.header-nav li {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  margin-right: 1rem;
}

.header-nav li:last-of-type {
  margin-right: 0;
}

.header-nav li .multi-word-item {
  text-wrap: nowrap; /* prevents it from going to 2 lines */
}

.expander {
  flex-grow: 1;
}

.header-nav a, .header-nav button {
  display: flex;
  gap: 0.375rem;
  font-size: var(--font-size-md);
  line-height: var(--font-size-md);
  align-items: center;
  height: 100%;
  border-radius: 0.313rem;
  transition: all 0.2s;
}

.header-nav a {
  color: var(--color-white);
  text-decoration: none;
}

.header-nav a:hover, .header-nav a:focus, 
.header-nav button:hover, .header-nav button:focus {
  color: var(--color-mpr-yellow);
}

.header-nav a svg, .header-nav button svg {
  transition: all 0.2s;
  flex-shrink: 0;
}

.header-nav a:hover svg, .header-nav a:focus svg, 
.header-nav button:hover svg, .header-nav button:focus svg {
  fill: var(--color-mpr-yellow);
}

.header-nav a:hover .stroke-icon, .header-nav a:focus .stroke-icon, 
.header-nav button:hover .stroke-icon, .header-nav button:focus .stroke-icon {
  fill: transparent;
  stroke: var(--color-mpr-yellow);
}

.header-nav button {
  border-width: 0;
  text-transform: none;
}

li.header-nav-large-mobile-item {
  display: none;
}

ul.header-nav-desktop {
  display: none;
}

.header-user {
  display: none;
}

.header-user img {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  border: 1px var(--color-white) solid;
}

.header-player {
  display: none;
  width: 28.75rem;
  height: 15.25rem;
}

.header-ad {
  display: inline-block;
  margin: 0 auto;
  grid-area: ad;
  text-align: center;
  width: 100%;
  position: relative;
}
.header-ad.app-ad {
  margin-bottom: 1rem;
}
.alert {
  position: relative;
}

.alert + .header-ad {
  margin-top: calc(0.5 * var(--gutter));
}

@media (min-width: 23em) {
  li.header-nav-large-mobile-item {
    display: block;
  }

  li.header-nav-small-mobile-item {
    display: none;
  }
}

@media (min-width: 32em) {
  .header-logo {
    height: 2em;
  }
}

@media (min-width: 65em) {
  .header {
    background-image: url('/img/header-bg-triangle.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right top;
  }

  .header:not(.header-home) {
    background-color: transparent;
    background-image: none;
  }

  .header:not(.header-home)::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    margin-top: calc(-1 * var(--gutter));
    width: 100%;
    height: 47rem;
    background-color: var(--color-mpr-charcoal);
    background-image: url('/img/header-color-lines.svg'), url('/img/header-bg-triangle.svg');
    background-repeat: no-repeat, no-repeat;
    background-size: 110%, auto;
    background-position: center bottom, right top;
  }

  .header-top {
    padding-top: 1.5rem;
    padding-bottom: 1.7rem;
  }

  .header-tagline {
    display: block;
  }

  ul.header-nav-mobile {
    display: none;
  }

  ul.header-nav-desktop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    
  }

  .header-nav li {
    margin-right: 0;
    position: relative;
  }
}

@media (min-width: 84em) {
  .header-contents-inner {
    max-width: calc(60rem - var(--gutter)); /* make room for header audio player */
  }
}

@media (min-width: 93rem) {
  .header-nav-desktop-outer {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  .header-user {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  .header-contents {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  /* change back to display:block if we want the player in the header */
  .header-player {
    display: none;
  }
}
