.collection_pagination {
  padding-top: 4rem;
}

.pagination-container {
  border-top: 1px var(--color-mpr-charcoal) solid;
}

.pagination {
  text-align: center;
}

.pagination_list {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}

.pagination_page-first {
  order: 2;
}

.pagination_page-first a {
  font-weight: bold;
}

.pagination_page-prev {
  order: 1;
  margin-right: 1.25rem;
}

.pagination_page-number {
  order: 50;
}

.pagination_page-next {
  order: 100;
  margin-left: 1.25rem;
}

.pagination_page-last {
  order: 99;
}

@media (max-width: 31.999em) {
  .pagination_page-first,
  .pagination_page-last {
    display: none;
  }
}

.pagination_link-number,
.pagination_link-first,
.pagination_link-last {
  padding: 0.625rem;
}

.pagination_link-current {
  font-weight: bold;
}
