.invisible, .skip-links>span, a.skiplink, a.skiplink:hover, a.skiplink:visited {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

a.skiplink:active, a.skiplink:focus {
  z-index: 500;
  top: auto;
  width: auto;
  height: auto;
  left: auto;
  padding: 10px;
  background: var(--color-white);
  color: var(--color-mpr-button-blue);
}
