body {
  --gradient-play: linear-gradient(327.56deg, #258D62 20.8%, #39D795 78.32%);
  --gradient-pause: linear-gradient(327.56deg, #C7432B 20.8%, #FF6C52 78.32%);
  --inner-glow: 1px 1px 3px 0rem #FFFFFF66 inset;
  --progress-color: var(--color-mpr-green);
}

.player-wrapper {
  margin: 0 auto;
  padding: 0;
  background: var(--color-mpr-charcoal);
  color: var(--color-white);
  box-shadow: 0rem -4px 1.25rem 0rem #0000004D, inset 0 1px #434246;
  border-radius: 2rem 2rem 0 0;
}

.player-wrapper-fixed {
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;
}

.player-wrapper:not(.player-wrapper-fixed) {
  height: 100%;
  width: 100%;
  border-radius: 2rem;
}

.player-wrapper-listenpage {
  position: relative;
}

.audioPlayer {
  display: flex;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 4.25rem;
  width: 100%;
  max-width: 87.5rem;
  position: relative;
  margin: 0 auto;
  font-weight: bold;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

@media (min-width: 48em) {
  .audioPlayer {
    min-height: 4.625rem;
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
  }
}

.player-layout {
  display: flex;
  padding: 0.75rem;
  width: inherit;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 48em) {
  .player-layout {
    padding: 0.5rem 1rem;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 1rem;
  }
}

.player-content {
  min-width: 0;
  width: 100%;
  padding: 0.375rem;
  display: flex;
  flex-shrink: 9;
  justify-content: left;
}

.player-toggle-container {
  display: inline-flex;
}

.player-timeline-time {
  display: grid;
  justify-content: space-between;
}

.player-audio-type {
  padding: 2px 4px;
  color: var(--color-mpr-yellow);
  margin-right: 0.375rem;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 48em) {
  .player-layout {
    flex-direction: row;
  }

  .player-timeline {
    display: none;
  }

  .player-open .player-timeline input {
    display: none;
  }

  .player-timeline:not(.player-controls-secondary) {
    display: none !important;
  }

  .player-open .player-content {
    display: inline-flex;
  }

  .player-content {
    display: inline-flex;
    order: 1
  }
}

.player-controls {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.player-backward-forward-controls button {
  padding: 0;
  border: none;
}

.player-backward-forward-controls button:hover:not(:disabled) {
  background: none;
}

.player-btn {
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  border-radius: 50%;
  position: relative;
}

.player-btn[disabled] {
  opacity: 0.3;
}

.player-btn svg {
  margin: auto;
}

.player-btn-play-pause-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.625rem;
  height: 3.625rem;
  background-image: linear-gradient(180deg, #000000 0%, #565454 100%),
    linear-gradient(180deg, rgba(98, 95, 95, 0) 0%, #282828 65%);
  box-shadow: 0 -1px #373636;
  margin-inline: 0.75rem;
}

.player-btn-playpause {
  width: 2.8rem;
  height: 2.8rem;
}

.player-btn-playpause, 
.player-btn-playpause:hover:not(:disabled), 
.player-btn-playpause:focus:not(:disabled) {
  background-image: var(--gradient-play);
  box-shadow: var(--inner-glow), 0rem 2px 3px 0rem #77767A66;
  transition: box-shadow 0.175s;
}

.player-btn-playpause:hover:not(:disabled), 
.player-btn-playpause:focus:not(:disabled) {
  box-shadow: 0 0 0 transparent inset, 0 0 .5rem var(--color-white);
}

.is-playing .player-btn-playpause, 
.is-playing .player-btn-playpause:hover:not(:disabled), 
.is-playing .player-btn-playpause:focus:not(:disabled) {
  background-image: var(--gradient-pause);
}

.player-play svg,
.player-pause svg{
  margin: auto;
  position: relative;
  fill: #ffffff;
  width: 2.25rem;
  height: 2.25rem;
  top: 0;
}

@media (min-width: 48em) {
  .player-play svg,
  .player-pause svg {
    top: 2px;
    width: 2.625rem;
    height: 2.625rem;
  }
}

.player-pause {
  display: none;
}

.player-toggle {
  border-radius: 0 0 3rem 3rem;
  background-color: var(--color-mpr-charcoal);
  position: absolute;
  right: 2rem;
  z-index: 2;
  width: 3rem;
  height: 1.5rem;
  top: -0.01rem;
  box-shadow: 2px 0 #181717, -2px 0 #494646;
}

.player-wrapper:not(.player-wrapper-fixed) .player-toggle {
  left: 50%;
}

.player-thumb-and-text {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.player-thumb-and-text > div {
  margin-right: 1rem;
}

.player-thumbnail {
  padding: 0.2rem;
  border-radius: 1rem;
  display: none;
}

div.player-thumbnail {
  background-image: linear-gradient(to bottom, #444, var(--color-mpr-charcoal));
}

.player-open .player-thumbnail {
  display: inline-block;
  border: 1px solid linear-gradient(180deg, #000000 0%, #565454 100%);
}

.player-open .player-timeline {
  display: inline-flex;
}

.player-thumbnail img {
  width: 5.875rem;
  height: 5.875rem;
}

.player-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.player-label {
  display: flex;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player-label-duration {
  padding-left: .5rem;
  text-transform: lowercase;
  color: var(--color-mpr-light-gray)
}

.player-text {
  position: relative;
}

.player-time {
  display: flex;
  align-items: center;
}

.player-listen-label {
  display: inline;
}

.player-description, .player-title {
  color: var(--color-white);
}

.player-top-duration {
  font-weight: normal;
  color: var(--color-mpr-light-gray)
}

.player.is-live .player-live-label {
  display: inline;
}

.player.is-live .player-listen-label,
.player.is-live .player-times,
.player.is-live .player-timeline-hidden,
.player.is-live .player-forward,
.player.is-live .player-rewind {
  display: none;
}

.listen-now-listening-to, .listen-audio-description {
  display: none;
}

.player-times {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: .75rem;
  color: var(--color-mpr-light-gray);
  order: 3;
}

.player-timeline {
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
}

.player-duration {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-currentTime {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-timeline input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: .813rem;
  background: #0000;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  border-bottom: 1px #4c4b4b solid;
  margin-top: 0.813rem;
  margin-bottom: 0.5rem;
}

.is-playing .player-timeline-buffered,
.is-paused .player-timeline-buffered {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

.is-playing .player-timeline-buffered > *,
.is-paused .player-timeline-buffered > * {
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
}

.player-controls-secondary-outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: normal;
  font-size: .75rem;
  color: var(--color-mpr-light-gray);
}

.player-controls-secondary {
  overflow: hidden;
}

.player-volume-icon div {
  display: flex;
  align-items: center;
  justify-content: baseline;
  cursor: pointer;
}

.player-volume-control img {
  margin: 0 .5rem;
  width: auto;
}

.player-volume-icon button {
  border: none;
  padding: 0;
}

.player-volume-icon button:hover:not(:disabled) {
  background: none;
}

.player-volume-progress {
  min-width: 5rem;
}

.player-timeline-progress {
  min-width: 14rem;
  margin-right: 1rem
}

@media (max-width: 48em) {
.player-controls-secondary-outer {
    display: none;
  }

.player-timeline {
  padding: 0;
}

.player-timeline-progress {
  width: 100%;
  height: auto;
  }
}

.player-volume-control {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.is-playing .player-play {
  display: none;
}
.is-playing .player-pause {
  display: block;
}
.is-playing .player-wave span {
  -webkit-animation: sound1 1000ms -800ms linear infinite normal;
          animation: sound1 1000ms -800ms linear infinite normal;
  opacity: 0.8;
}
.is-playing .player-wave span:nth-child(1) {
  -webkit-animation-duration: 780ms;
          animation-duration: 780ms;
  -webkit-animation-name: sound1;
          animation-name: sound1;
  height: 75%;
}
.is-playing .player-wave span:nth-child(2) {
  -webkit-animation-duration: 750ms;
          animation-duration: 750ms;
  -webkit-animation-name: sound2;
          animation-name: sound2;
  height: 50%;
}
.is-playing .player-wave span:nth-child(3) {
  -webkit-animation-duration: 790ms;
          animation-duration: 790ms;
  -webkit-animation-name: sound3;
          animation-name: sound3;
  height: 100%;
}
.is-playing .player-wave span:nth-child(4) {
  -webkit-animation-duration: 760ms;
          animation-duration: 760ms;
  -webkit-animation-name: sound4;
          animation-name: sound4;
  height: 70%;
}

.player-mute {
  display: none;
}

.is-muted .player-mute {
  display: block;
}

@media (max-width: 31em) {
  .player-wrapper input[type='range'] {
    display: none;
  }

  .player-open input[type='range'] {
    display: none;
  }

  .player-times {
    display: none;
  }

  .player-open .player-times {
    display: none;
  }
}

.player-wrapper input[type="range"] {
  appearance: none;
  height: .8rem;
  padding: 0;
  background-color: #000;
  border: none;
  border-bottom: 1px #4c4b4b solid;
  cursor: pointer;
  width: 100%;
}

.player-wrapper input[type='range']::-webkit-slider-runnable-track {
  appearance: none;
  border-radius: 1rem;
  height: .75rem;
  margin-top: -1px;
}

.player-wrapper input[type='range']::-webkit-slider-thumb {
  appearance: none;
  background: var(--gradient-play);
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  margin-top: -0.4rem;
  box-shadow: 1px 1px 3px 0px #FFFFFF66 inset, 0px 2px 3px 0px #77767A66,
              /* the following create the the progress bar */
              -0.5625rem 0 0 -0.4rem var(--progress-color),
              -0.75rem 0 0 -0.4rem var(--progress-color),
              -0.9375rem 0 0 -0.4rem var(--progress-color),
              -1.125rem 0 0 -0.4rem var(--progress-color),
              -1.3125rem 0 0 -0.4rem var(--progress-color),
              -1.5rem 0 0 -0.4rem var(--progress-color),
              -1.6875rem 0 0 -0.4rem var(--progress-color),
              -1.875rem 0 0 -0.4rem var(--progress-color),
              -2.0625rem 0 0 -0.4rem var(--progress-color),
              -2.25rem 0 0 -0.4rem var(--progress-color),
              -2.4375rem 0 0 -0.4rem var(--progress-color),
              -2.625rem 0 0 -0.4rem var(--progress-color),
              -2.8125rem 0 0 -0.4rem var(--progress-color),
              -3rem 0 0 -0.4rem var(--progress-color),
              -3.1875rem 0 0 -0.4rem var(--progress-color),
              -3.375rem 0 0 -0.4rem var(--progress-color),
              -3.5625rem 0 0 -0.4rem var(--progress-color),
              -3.75rem 0 0 -0.4rem var(--progress-color),
              -3.9375rem 0 0 -0.4rem var(--progress-color),
              -4.125rem 0 0 -0.4rem var(--progress-color),
              -4.3125rem 0 0 -0.4rem var(--progress-color),
              -4.5rem 0 0 -0.4rem var(--progress-color),
              -4.6875rem 0 0 -0.4rem var(--progress-color),
              -4.875rem 0 0 -0.4rem var(--progress-color),
              -5.0625rem 0 0 -0.4rem var(--progress-color),
              -5.25rem 0 0 -0.4rem var(--progress-color),
              -5.4375rem 0 0 -0.4rem var(--progress-color),
              -5.625rem 0 0 -0.4rem var(--progress-color),
              -5.8125rem 0 0 -0.4rem var(--progress-color),
              -6rem 0 0 -0.4rem var(--progress-color),
              -6.1875rem 0 0 -0.4rem var(--progress-color),
              -6.375rem 0 0 -0.4rem var(--progress-color),
              -6.5625rem 0 0 -0.4rem var(--progress-color),
              -6.75rem 0 0 -0.4rem var(--progress-color),
              -6.9375rem 0 0 -0.4rem var(--progress-color),
              -7.125rem 0 0 -0.4rem var(--progress-color),
              -7.3125rem 0 0 -0.4rem var(--progress-color),
              -7.5rem 0 0 -0.4rem var(--progress-color),
              -7.6875rem 0 0 -0.4rem var(--progress-color),
              -7.875rem 0 0 -0.4rem var(--progress-color),
              -8.0625rem 0 0 -0.4rem var(--progress-color),
              -8.25rem 0 0 -0.4rem var(--progress-color),
              -8.4375rem 0 0 -0.4rem var(--progress-color),
              -8.6325rem 0 0 -0.4rem var(--progress-color),
              -8.8125rem 0 0 -0.4rem var(--progress-color),
              -9rem 0 0 -0.4rem var(--progress-color),
              -9.1875rem 0 0 -0.4rem var(--progress-color),
              -9.375rem 0 0 -0.4rem var(--progress-color),
              -9.5625rem 0 0 -0.4rem var(--progress-color),
              -9.75rem 0 0 -0.4rem var(--progress-color),
              -9.9375rem 0 0 -0.4rem var(--progress-color),
              -10.125rem 0 0 -0.4rem var(--progress-color),
              -10.3125rem 0 0 -0.4rem var(--progress-color),
              -10.5rem 0 0 -0.4rem var(--progress-color),
              -10.6875rem 0 0 -0.4rem var(--progress-color),
              -10.875rem 0 0 -0.4rem var(--progress-color),
              -11.0625rem 0 0 -0.4rem var(--progress-color),
              -11.25rem 0 0 -0.4rem var(--progress-color),
              -11.4375rem 0 0 -0.4rem var(--progress-color),
              -11.6325rem 0 0 -0.4rem var(--progress-color),
              -11.8125rem 0 0 -0.4rem var(--progress-color),
              -12rem 0 0 -0.4rem var(--progress-color),
              -12.1875rem 0 0 -0.4rem var(--progress-color),
              -12.375rem 0 0 -0.4rem var(--progress-color),
              -12.5625rem 0 0 -0.4rem var(--progress-color),
              -12.75rem 0 0 -0.4rem var(--progress-color),
              -12.9375rem 0 0 -0.4rem var(--progress-color),
              -13.125rem 0 0 -0.4rem var(--progress-color),
              -13.3125rem 0 0 -0.4rem var(--progress-color),
              -13.5rem 0 0 -0.4rem var(--progress-color),
              -13.6875rem 0 0 -0.4rem var(--progress-color),
              -13.875rem 0 0 -0.4rem var(--progress-color),
              -14.0625rem 0 0 -0.4rem var(--progress-color),
              -14.25rem 0 0 -0.4rem var(--progress-color),
              -14.4375rem 0 0 -0.4rem var(--progress-color),
              -14.6325rem 0 0 -0.4rem var(--progress-color),
              -14.8125rem 0 0 -0.4rem var(--progress-color),
              -15rem 0 0 -0.4rem var(--progress-color),
              -15.1875rem 0 0 -0.4rem var(--progress-color),
              -15.375rem 0 0 -0.4rem var(--progress-color),
              -15.5625rem 0 0 -0.4rem var(--progress-color),
              -15.75rem 0 0 -0.4rem var(--progress-color),
              -15.9375rem 0 0 -0.4rem var(--progress-color),
              -16.125rem 0 0 -0.4rem var(--progress-color),
              -16.3125rem 0 0 -0.4rem var(--progress-color),
              -16.5rem 0 0 -0.4rem var(--progress-color),
              -16.6875rem 0 0 -0.4rem var(--progress-color),
              -16.875rem 0 0 -0.4rem var(--progress-color),
              -17.0625rem 0 0 -0.4rem var(--progress-color),
              -17.25rem 0 0 -0.4rem var(--progress-color),
              -17.4375rem 0 0 -0.4rem var(--progress-color),
              -17.6325rem 0 0 -0.4rem var(--progress-color),
              -17.8125rem 0 0 -0.4rem var(--progress-color),
              -18rem 0 0 -0.4rem var(--progress-color),
              -18.1875rem 0 0 -0.4rem var(--progress-color),
              -18.375rem 0 0 -0.4rem var(--progress-color),
              -18.5625rem 0 0 -0.4rem var(--progress-color),
              -18.75rem 0 0 -0.4rem var(--progress-color),
              -18.9375rem 0 0 -0.4rem var(--progress-color),
              -19.125rem 0 0 -0.4rem var(--progress-color),
              -19.3125rem 0 0 -0.4rem var(--progress-color),
              -19.5rem 0 0 -0.4rem var(--progress-color),
              -19.6875rem 0 0 -0.4rem var(--progress-color),
              -19.875rem 0 0 -0.4rem var(--progress-color),
              -20.0625rem 0 0 -0.4rem var(--progress-color),
              -20.25rem 0 0 -0.4rem var(--progress-color),
              -20.4375rem 0 0 -0.4rem var(--progress-color),
              -20.6325rem 0 0 -0.4rem var(--progress-color),
              -20.8125rem 0 0 -0.4rem var(--progress-color),
              -21rem 0 0 -0.4rem var(--progress-color),
              -21.1875rem 0 0 -0.4rem var(--progress-color),
              -21.375rem 0 0 -0.4rem var(--progress-color),
              -21.5625rem 0 0 -0.4rem var(--progress-color),
              -21.75rem 0 0 -0.4rem var(--progress-color),
              -21.9375rem 0 0 -0.4rem var(--progress-color),
              -22.125rem 0 0 -0.4rem var(--progress-color),
              -22.3125rem 0 0 -0.4rem var(--progress-color),
              -22.5rem 0 0 -0.4rem var(--progress-color),
              -22.6875rem 0 0 -0.4rem var(--progress-color),
              -22.875rem 0 0 -0.4rem var(--progress-color),
              -23.0625rem 0 0 -0.4rem var(--progress-color),
              -23.25rem 0 0 -0.4rem var(--progress-color),
              -23.4375rem 0 0 -0.4rem var(--progress-color),
              -23.6325rem 0 0 -0.4rem var(--progress-color),
              -23.8125rem 0 0 -0.4rem var(--progress-color),
              -24rem 0 0 -0.4rem var(--progress-color),
              -24.1875rem 0 0 -0.4rem var(--progress-color),
              -24.375rem 0 0 -0.4rem var(--progress-color),
              -24.5625rem 0 0 -0.4rem var(--progress-color),
              -24.75rem 0 0 -0.4rem var(--progress-color),
              -24.9375rem 0 0 -0.4rem var(--progress-color),
              -25.125rem 0 0 -0.4rem var(--progress-color),
              -25.3125rem 0 0 -0.4rem var(--progress-color),
              -25.5rem 0 0 -0.4rem var(--progress-color),
              -25.6875rem 0 0 -0.4rem var(--progress-color),
              -25.875rem 0 0 -0.4rem var(--progress-color),
              -26.0625rem 0 0 -0.4rem var(--progress-color),
              -26.25rem 0 0 -0.4rem var(--progress-color),
              -26.4375rem 0 0 -0.4rem var(--progress-color),
              -26.6325rem 0 0 -0.4rem var(--progress-color),
              -26.8125rem 0 0 -0.4rem var(--progress-color),
              -27rem 0 0 -0.4rem var(--progress-color),
              -27.1875rem 0 0 -0.4rem var(--progress-color),
              -27.375rem 0 0 -0.4rem var(--progress-color),
              -27.5625rem 0 0 -0.4rem var(--progress-color),
              -27.75rem 0 0 -0.4rem var(--progress-color),
              -27.9375rem 0 0 -0.4rem var(--progress-color),
              -28.125rem 0 0 -0.4rem var(--progress-color),
              -28.3125rem 0 0 -0.4rem var(--progress-color),
              -28.5rem 0 0 -0.4rem var(--progress-color),
              -28.6875rem 0 0 -0.4rem var(--progress-color),
              -28.875rem 0 0 -0.4rem var(--progress-color),
              -29.0625rem 0 0 -0.4rem var(--progress-color),
              -29.25rem 0 0 -0.4rem var(--progress-color),
              -29.4375rem 0 0 -0.4rem var(--progress-color),
              -29.6325rem 0 0 -0.4rem var(--progress-color),
              -29.8125rem 0 0 -0.4rem var(--progress-color),
              -30rem 0 0 -0.4rem var(--progress-color),
              -30.1875rem 0 0 -0.4rem var(--progress-color),
              -30.375rem 0 0 -0.4rem var(--progress-color),
              -30.5625rem 0 0 -0.4rem var(--progress-color),
              -30.75rem 0 0 -0.4rem var(--progress-color),
              -30.9375rem 0 0 -0.4rem var(--progress-color),
              -31.125rem 0 0 -0.4rem var(--progress-color),
              -31.3125rem 0 0 -0.4rem var(--progress-color),
              -31.5rem 0 0 -0.4rem var(--progress-color),
              -31.6875rem 0 0 -0.4rem var(--progress-color),
              -31.875rem 0 0 -0.4rem var(--progress-color),
              -32.0625rem 0 0 -0.4rem var(--progress-color),
              -32.25rem 0 0 -0.4rem var(--progress-color),
              -32.4375rem 0 0 -0.4rem var(--progress-color),
              -32.6325rem 0 0 -0.4rem var(--progress-color),
              -32.8125rem 0 0 -0.4rem var(--progress-color),
              -33rem 0 0 -0.4rem var(--progress-color),
              -33.1875rem 0 0 -0.4rem var(--progress-color),
              -33.375rem 0 0 -0.4rem var(--progress-color),
              -33.5625rem 0 0 -0.4rem var(--progress-color),
              -33.75rem 0 0 -0.4rem var(--progress-color),
              -33.9375rem 0 0 -0.4rem var(--progress-color),
              -34.125rem 0 0 -0.4rem var(--progress-color),
              -34.3125rem 0 0 -0.4rem var(--progress-color),
              -34.5rem 0 0 -0.4rem var(--progress-color),
              -34.6875rem 0 0 -0.4rem var(--progress-color),
              -34.875rem 0 0 -0.4rem var(--progress-color),
              -35.0625rem 0 0 -0.4rem var(--progress-color),
              -35.25rem 0 0 -0.4rem var(--progress-color),
              -35.4375rem 0 0 -0.4rem var(--progress-color),
              -35.6325rem 0 0 -0.4rem var(--progress-color),
              -35.8125rem 0 0 -0.4rem var(--progress-color),
              -36rem 0 0 -0.4rem var(--progress-color);
}

.player-wrapper input[type='range']::-moz-range-thumb {
  appearance: none;
  background: var(--gradient-play);
  box-shadow: 1px 1px 3px 0px #FFFFFF66 inset, 0px 2px 3px 0px #77767A66;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  margin-top: -0.4rem;
  border: none;
}

.player-wrapper input[type="range"]::-moz-range-progress {
  background-color: var(--progress-color);
  height: .8rem;
}

.play-volume-timeline input[type="range"] {
  display: none;
}

.is-playing .player-timeline-buffered .player-timeline_loading,
.is-paused .player-timeline-buffered .player-timeline_loading {
  display: none;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  background-image: repeating-linear-gradient(-45deg, hsl(195, 3%, 10%), hsl(195, 3%, 10%) 0.6875rem, hsl(195, 3%, 80%) 0.625rem, hsl(195, 3%, 80%) 1.25rem);
  background-size: 1.75rem 1.75rem;
  -webkit-animation: loading 0.5s linear infinite;
          animation: loading 0.5s linear infinite;
}

.is-loading .player-wave span {
  -webkit-animation: loading1 500ms linear infinite normal;
          animation: loading1 500ms linear infinite normal;
  height: 100%;
}
.is-loading .player-wave span:nth-child(1) {
  -webkit-animation-name: loading1;
          animation-name: loading1;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  height: 100%;
}
.is-loading .player-wave span:nth-child(2) {
  -webkit-animation-name: loading2;
          animation-name: loading2;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  height: 100%;
}
.is-loading .player-wave span:nth-child(3) {
  -webkit-animation-name: loading3;
          animation-name: loading3;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  height: 100%;
}
.is-loading .player-wave span:nth-child(4) {
  -webkit-animation-name: loading4;
          animation-name: loading4;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  height: 100%;
}
.is-loading .player-play::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2rem;
  height: 2rem;
  margin-top: -1.5625rem;
  margin-left: -1.5625rem;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #4298b5;
  border-bottom-color: #4298b5;
  -webkit-animation: spinner 1.5s linear infinite;
          animation: spinner 1.5s linear infinite;
}
@media (min-width: 48em) {
  .is-loading .player-play::before {
    width: 3.125rem;
    height: 3.125rem;
  }
}

.is-paused .player-wave span {
  -webkit-animation: none;
          animation: none;
}
.is-paused .player-wave span:nth-child(1) {
  left: 0;
}
.is-paused .player-wave span:nth-child(2) {
  left: 0.3125rem;
}
.is-paused .player-wave span:nth-child(3) {
  left: 0.625rem;
}
.is-paused .player-wave span:nth-child(4) {
  left: 0.9375rem;
}

/* move the below into a new file eventually */
.playerFooter {
  display: flex;
  flex-direction: column;
  background-color: #dae9f1;
  margin: auto;
  justify-content: space-evenly;
  max-width: 87.5rem;
  padding: 0.5rem 1rem;
}
@media (min-width: 48em) {
  .playerFooter {
    flex-direction: row;
  }
}

.playerFooter_row {
  margin-bottom: 1.5rem;
}

.playerFooter_column {
  margin: 1.125rem 0;
}
@media (min-width: 48em) {
  .playerFooter_column {
    margin: 1.5rem 0;
  }
}

@media (min-width: 84em) {
  .player-wrapper-header {
    position: absolute;
    top: 1rem;
    width: 19.688rem;
    height: 8.4rem;
    border-radius: .5rem;
    border: 1px var(--color-mpr-dark-gray) solid;
    right: max(var(--gutter), 50vw - 40rem);
  }

  .player-wrapper-header.player-wrapper-live {
    height: 7rem;
  }

  .player-wrapper-header .player-controls-secondary-outer, 
  .player-wrapper-header .player-toggle-container,
  .player-wrapper-header .player-backward-forward-controls {
    display: none;
  }

  .player-wrapper-header .player-layout {
    padding: .15rem .5rem;
    display: grid;
    gap: 0;
    grid-template-columns: 20% auto;
  }

  .player-wrapper-header .player-thumbnail {
    display: block;
    padding: 0;
    background: none;
    border-radius: 0.5rem;
    margin-right: 0.25rem;
    position: relative;
    left: -2.03rem;
  }

  .player-wrapper-header .player-controls {
    position: relative;
    left: 6.4rem;
    align-items: start;
    margin-top: .4rem;
    z-index: 2;
  }

  .player-wrapper-header .player-btn-play-pause-outer {
    margin-inline: 0;
  }

  .player-wrapper-header .player-audio-type {
    margin-right: 0;
    padding-right: 0;
    justify-content: start;
  }

  .player-wrapper-header .player-title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-transform: none;
  }

  .player-wrapper-header .player-label-duration {
    display: none;
  }

  .player-wrapper-header .player-timeline {
    order: 2;
    grid-column: span 2;
    padding-top: 0;
  }

  .player-wrapper.player-wrapper-header .player-timeline-progress-outer {
    overflow-x: hidden;
    width: 70%;
    margin-inline: auto;
  }

  .player-wrapper.player-wrapper-header input[type="range"] {
    height: .5rem;
    margin-inline: auto;
    margin-top: 0.5rem;
    min-width: 0;
  }

  .player-wrapper.player-wrapper-header input[type="range"]::-moz-range-progress {
    height: .5rem;
  }

  .player-wrapper.player-wrapper-header input[type='range']::-webkit-slider-thumb {
    height: 0.875rem;
    width: 0.875rem;
  }

  .player-wrapper.player-wrapper-header input[type='range']::-moz-range-thumb {
    height: 0.875rem;
    width: 0.875rem;
  }

  .player-wrapper.player-wrapper-header input[type='range']::-webkit-slider-runnable-track {
    margin-top: 0.6rem;
  }

  .player-wrapper.player-wrapper-header input[type='range']::-webkit-slider-thumb {
    box-shadow: 1px 1px 3px 0px #FFFFFF66 inset, 0px 2px 3px 0px #77767A66,
              /* the following create the the progress bar */
              -0.125rem 0 0 -0.2rem var(--progress-color),
              -0.25rem 0 0 -0.2rem var(--progress-color),
              -0.375rem 0 0 -0.2rem var(--progress-color),
              -0.5rem 0 0 -0.2rem var(--progress-color),
              -0.625rem 0 0 -0.2rem var(--progress-color),
              -0.75rem 0 0 -0.2rem var(--progress-color),
              -0.875rem 0 0 -0.2rem var(--progress-color),
              -1rem 0 0 -0.2rem var(--progress-color),
              -1.125rem 0 0 -0.2rem var(--progress-color),
              -1.25rem 0 0 -0.2rem var(--progress-color),
              -1.375rem 0 0 -0.2rem var(--progress-color),
              -1.5rem 0 0 -0.2rem var(--progress-color),
              -1.625rem 0 0 -0.2rem var(--progress-color),
              -1.75rem 0 0 -0.2rem var(--progress-color),
              -1.875rem 0 0 -0.2rem var(--progress-color),
              -2rem 0 0 -0.2rem var(--progress-color),
              -2.125rem 0 0 -0.2rem var(--progress-color),
              -2.25rem 0 0 -0.2rem var(--progress-color),
              -2.375rem 0 0 -0.2rem var(--progress-color),
              -2.5rem 0 0 -0.2rem var(--progress-color),
              -2.625rem 0 0 -0.2rem var(--progress-color),
              -2.75rem 0 0 -0.2rem var(--progress-color),
              -2.875rem 0 0 -0.2rem var(--progress-color),
              -3rem 0 0 -0.2rem var(--progress-color),
              -3.125rem 0 0 -0.2rem var(--progress-color),
              -3.25rem 0 0 -0.2rem var(--progress-color),
              -3.375rem 0 0 -0.2rem var(--progress-color),
              -3.5rem 0 0 -0.2rem var(--progress-color),
              -3.625rem 0 0 -0.2rem var(--progress-color),
              -3.75rem 0 0 -0.2rem var(--progress-color),
              -3.875rem 0 0 -0.2rem var(--progress-color),
              -4rem 0 0 -0.2rem var(--progress-color),
              -4.125rem 0 0 -0.2rem var(--progress-color),
              -4.25rem 0 0 -0.2rem var(--progress-color),
              -4.375rem 0 0 -0.2rem var(--progress-color),
              -4.5rem 0 0 -0.2rem var(--progress-color),
              -4.625rem 0 0 -0.2rem var(--progress-color),
              -4.75rem 0 0 -0.2rem var(--progress-color),
              -4.875rem 0 0 -0.2rem var(--progress-color),
              -5rem 0 0 -0.2rem var(--progress-color),
              -5.125rem 0 0 -0.2rem var(--progress-color),
              -5.25rem 0 0 -0.2rem var(--progress-color),
              -5.375rem 0 0 -0.2rem var(--progress-color),
              -5.5rem 0 0 -0.2rem var(--progress-color),
              -5.625rem 0 0 -0.2rem var(--progress-color),
              -5.75rem 0 0 -0.2rem var(--progress-color),
              -5.875rem 0 0 -0.2rem var(--progress-color),
              -6rem 0 0 -0.2rem var(--progress-color),
              -6.125rem 0 0 -0.2rem var(--progress-color),
              -6.25rem 0 0 -0.2rem var(--progress-color),
              -6.375rem 0 0 -0.2rem var(--progress-color),
              -6.5rem 0 0 -0.2rem var(--progress-color),
              -6.625rem 0 0 -0.2rem var(--progress-color),
              -6.75rem 0 0 -0.2rem var(--progress-color),
              -6.875rem 0 0 -0.2rem var(--progress-color),
              -7rem 0 0 -0.2rem var(--progress-color),
              -7.125rem 0 0 -0.2rem var(--progress-color),
              -7.25rem 0 0 -0.2rem var(--progress-color),
              -7.375rem 0 0 -0.2rem var(--progress-color),
              -7.5rem 0 0 -0.2rem var(--progress-color),
              -7.625rem 0 0 -0.2rem var(--progress-color),
              -7.75rem 0 0 -0.2rem var(--progress-color),
              -7.875rem 0 0 -0.2rem var(--progress-color),
              -8rem 0 0 -0.2rem var(--progress-color),
              -8.125rem 0 0 -0.2rem var(--progress-color),
              -8.25rem 0 0 -0.2rem var(--progress-color),
              -8.375rem 0 0 -0.2rem var(--progress-color),
              -8.5rem 0 0 -0.2rem var(--progress-color),
              -8.625rem 0 0 -0.2rem var(--progress-color),
              -8.75rem 0 0 -0.2rem var(--progress-color),
              -8.875rem 0 0 -0.2rem var(--progress-color),
              -9rem 0 0 -0.2rem var(--progress-color),
              -9.125rem 0 0 -0.2rem var(--progress-color),
              -9.25rem 0 0 -0.2rem var(--progress-color),
              -9.375rem 0 0 -0.2rem var(--progress-color),
              -9.5rem 0 0 -0.2rem var(--progress-color),
              -9.625rem 0 0 -0.2rem var(--progress-color),
              -9.75rem 0 0 -0.2rem var(--progress-color),
              -9.875rem 0 0 -0.2rem var(--progress-color),
              -10rem 0 0 -0.2rem var(--progress-color),
              -10.125rem 0 0 -0.2rem var(--progress-color),
              -10.25rem 0 0 -0.2rem var(--progress-color),
              -10.375rem 0 0 -0.2rem var(--progress-color),
              -10.5rem 0 0 -0.2rem var(--progress-color),
              -10.625rem 0 0 -0.2rem var(--progress-color),
              -10.75rem 0 0 -0.2rem var(--progress-color),
              -10.875rem 0 0 -0.2rem var(--progress-color),
              -11rem 0 0 -0.2rem var(--progress-color),
              -11.125rem 0 0 -0.2rem var(--progress-color),
              -11.25rem 0 0 -0.2rem var(--progress-color),
              -11.375rem 0 0 -0.2rem var(--progress-color),
              -11.5rem 0 0 -0.2rem var(--progress-color),
              -11.625rem 0 0 -0.2rem var(--progress-color),
              -11.75rem 0 0 -0.2rem var(--progress-color),
              -11.875rem 0 0 -0.2rem var(--progress-color),
              -12rem 0 0 -0.2rem var(--progress-color),
              -12.125rem 0 0 -0.2rem var(--progress-color),
              -12.25rem 0 0 -0.2rem var(--progress-color),
              -12.375rem 0 0 -0.2rem var(--progress-color),
              -12.5rem 0 0 -0.2rem var(--progress-color),
              -12.625rem 0 0 -0.2rem var(--progress-color),
              -12.75rem 0 0 -0.2rem var(--progress-color),
              -12.875rem 0 0 -0.2rem var(--progress-color),
              -13rem 0 0 -0.2rem var(--progress-color),
              -13.125rem 0 0 -0.2rem var(--progress-color),
              -13.25rem 0 0 -0.2rem var(--progress-color),
              -13.375rem 0 0 -0.2rem var(--progress-color),
              -13.5rem 0 0 -0.2rem var(--progress-color),
              -13.625rem 0 0 -0.2rem var(--progress-color),
              -13.75rem 0 0 -0.2rem var(--progress-color),
              -13.875rem 0 0 -0.2rem var(--progress-color),
              -14rem 0 0 -0.2rem var(--progress-color),
              -14.125rem 0 0 -0.2rem var(--progress-color),
              -14.25rem 0 0 -0.2rem var(--progress-color),
              -14.375rem 0 0 -0.2rem var(--progress-color),
              -14.5rem 0 0 -0.2rem var(--progress-color),
              -14.625rem 0 0 -0.2rem var(--progress-color),
              -14.75rem 0 0 -0.2rem var(--progress-color),
              -14.875rem 0 0 -0.2rem var(--progress-color),
              -15rem 0 0 -0.2rem var(--progress-color),
              -15.125rem 0 0 -0.2rem var(--progress-color),
              -15.25rem 0 0 -0.2rem var(--progress-color),
              -15.375rem 0 0 -0.2rem var(--progress-color),
              -15.5rem 0 0 -0.2rem var(--progress-color),
              -15.625rem 0 0 -0.2rem var(--progress-color),
              -15.75rem 0 0 -0.2rem var(--progress-color),
              -15.875rem 0 0 -0.2rem var(--progress-color),
              -16rem 0 0 -0.2rem var(--progress-color),
              -16.125rem 0 0 -0.2rem var(--progress-color),
              -16.25rem 0 0 -0.2rem var(--progress-color),
              -16.375rem 0 0 -0.2rem var(--progress-color),
              -16.5rem 0 0 -0.2rem var(--progress-color),
              -16.625rem 0 0 -0.2rem var(--progress-color),
              -16.75rem 0 0 -0.2rem var(--progress-color),
              -16.875rem 0 0 -0.2rem var(--progress-color),
              -17rem 0 0 -0.2rem var(--progress-color),
              -17.125rem 0 0 -0.2rem var(--progress-color),
              -17.25rem 0 0 -0.2rem var(--progress-color),
              -17.375rem 0 0 -0.2rem var(--progress-color),
              -17.5rem 0 0 -0.2rem var(--progress-color),
              -17.625rem 0 0 -0.2rem var(--progress-color),
              -17.75rem 0 0 -0.2rem var(--progress-color),
              -17.875rem 0 0 -0.2rem var(--progress-color),
              -18rem 0 0 -0.2rem var(--progress-color),
              -18.125rem 0 0 -0.2rem var(--progress-color),
              -18.25rem 0 0 -0.2rem var(--progress-color),
              -18.375rem 0 0 -0.2rem var(--progress-color),
              -18.5rem 0 0 -0.2rem var(--progress-color),
              -18.625rem 0 0 -0.2rem var(--progress-color),
              -18.75rem 0 0 -0.2rem var(--progress-color),
              -18.875rem 0 0 -0.2rem var(--progress-color),
              -19rem 0 0 -0.2rem var(--progress-color),
              -19.125rem 0 0 -0.2rem var(--progress-color),
              -19.25rem 0 0 -0.2rem var(--progress-color),
              -19.375rem 0 0 -0.2rem var(--progress-color),
              -19.5rem 0 0 -0.2rem var(--progress-color),
              -19.625rem 0 0 -0.2rem var(--progress-color),
              -19.75rem 0 0 -0.2rem var(--progress-color),
              -19.875rem 0 0 -0.2rem var(--progress-color),
              -20rem 0 0 -0.2rem var(--progress-color),
              -20.125rem 0 0 -0.2rem var(--progress-color),
              -20.25rem 0 0 -0.2rem var(--progress-color),
              -20.375rem 0 0 -0.2rem var(--progress-color),
              -20.5rem 0 0 -0.2rem var(--progress-color)
  }

  .player-wrapper-header .player-times {
    position: relative;
    top: -1.35rem;
    z-index: -1;
  }
}

.app-audio .player-backward-forward-controls,
.app-audio .player-timeline,
.app-audio .player-content {
  display: none;
}

.app-audio .player-btn-play-pause-outer {
  background-image: none;
  background-color: var(--color-mpr-green);
  box-shadow: none;
}
.app-audio  svg {
  fill: var(--color-white) !important;
}
