/* wrappers */

.container {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: var(--gutter);
}

.centerContent {
  margin-bottom: 1rem;
}

.centerContent div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal {
  color: var(--color-mpr-charcoal);
  background-color: white;
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
}

.modal form input {
  padding: 0.25rem 0.5rem 0.375rem;
}

.modalContainerLeft_signIn {
  position: relative;
  background: url('/img/modal-sign-in.jpg') no-repeat;
  background-size: cover;
  background-position: center;
}

.modalContainerLeft_signUp {
  position: relative;
  background: url('/img/sign-up-modal.jpg') no-repeat;
  background-size: cover;
}

.modalContainerLeft_confirmUser {
  position: relative;
  background: url('/img/verify-email-modal.jpg') no-repeat;
  background-size: cover;
}

.modalContainerLeft_resetPassword {
  position: relative;
  background: url('/img/password-reset-modal.jpg') no-repeat;
  background-size: cover;
}

.modalInnerWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.modalOverlayImageOne {
  max-width: 80%;
}

.modalOverlay {
  bottom: 0;
}

.modalOverlayText {
  padding: 1rem 4rem;
  background-color: var(--color-mpr-charcoal);
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 90%;
}

.modalOverlayImageTwoOuter {
  width: 100%;
  text-align: right;
  line-height: 0;
}

.modalOverlayImageTwo {
  position: relative;
  max-width: 80%;
  display: inline-block;
}

.modalForYou {
  color: var(--color-mpr-yellow);
}

.modalContainerRight {
  margin: 0;
  padding: var(--gutter);
  padding-left: 0;
  padding-right: 3.5rem;
  max-height: 85vh;
  overflow-y: auto;
}

.containerInner {
  gap: 2rem;
  align-items: center;
  margin-top: 2.5rem;
}

.child:first-child {
  width: 40%;
}

.child:last-child {
  width: 60%;
}

.topContainer div {
  font-weight: 600;
  margin-bottom: var(--line-height-xl);
}

.topContainer,
.closeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.incorrectCredentials {
  color: var(--color-current-red);
}

.closeButtonContainer h2 {
  margin: 0;
}

/* forms */

.frm {
  display: flex;
  flex-direction: column;
}

.lbl {
  font-weight: 500;
  margin: 0 0.5rem 0.5rem 0;
}

.oneInputForm .lbl {
  display: block;
}

.inpt {
  margin-bottom: 1rem;
}

.confirmContainer {
  display: flex;
  text-align: center;
}

.oneInputForm .inpt {
  margin-right: 1rem;
}

.largeInput {
  width: 100%;
  display: block;
}

.inpt:last-of-type {
  letter-spacing: 0.063rem;
  margin-bottom: 0;
}

.dontCloseOnClickOutside::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-bkgd-dk-trans);
  z-index: -1;
}

/* text  */

.strong {
  font-weight: 600;
}

.hdglight {
  font-weight: normal;
  margin-bottom: 2rem;
}

.hdglight h2 {
  margin-top: 3rem;
}

/* supplemental text & links */

.supplementalText {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-size: 0.775rem;
}

.supplementalText a {
  margin-bottom: 1.5rem;
}

.supplementalTextBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 1rem;
}

.supplementalTextBottom div {
  padding-left: 0.4rem;
}

.error {
  color: var(--color-mpr-red-dark);
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

/* lists  */

.bulletlist {
  list-style: disc;
  padding-left: 1rem;
  line-height: 1.875rem;
}

.bulletlist li {
  margin-bottom: 1rem;
}

/* utility  */

.block {
  display: block;
}

.fullwidth {
  width: 100%;
}

/* button */

.btn {
  border-color: transparent;
  border-radius: 1rem;
  font-size: smaller;
  padding: 0.5rem 0;
  text-transform: uppercase;
  width: 40%;
}

.closeButton {
  display: flex;
  margin-left: 2rem;
  cursor: pointer;
  border: none;
  background: transparent;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

/* media query */

@media (min-width: 30em) {
  .oneInputForm h2 span {
    display: block;
  }
}

@media (min-width: 100em) {
  .containerNewUser {
    width: 70rem;
  }
}

@media (max-width: 64em) {
  .inpt {
    min-width: 0;
  }

  .smallInputContainer {
    display: block;
  }
}

@media (max-width: 50em) {
  .container {
    flex-direction: column;
  }

  .child,
  .child:first-child,
  .child:last-child {
    width: 100%;
  }
}

@media (max-width: 55em) {
  .modalContainerLeft {
    background: var(--color-mpr-charcoal);
  }
}

@media screen and (max-width: 28em) {
  .container {
    gap: 1rem;
  }

  .hdglight {
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }
}
