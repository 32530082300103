.menu {
  background-color: var(--color-white);
  color: var(--color-mpr-charcoal);
  position: absolute;
  left: 0;
  z-index: 100000;
  /* ^ this is needed so that the menu is on top of the live radar,
  even if it has the "radar has expired" overlay */
  box-shadow: var(--subtle-shadow);
  margin-top: 1rem;
  max-width: 100vw;
  border: 1px var(--color-mpr-light-gray) solid;
  border-radius: 0 0 3px 3px;
  width: 85%;
}

ul.menu-list {
  display: block;
}

.menu-label {
  border-bottom: 1px var(--color-mpr-light-gray) solid;
  padding: 0.5rem;
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
  background-color: var(--color-mpr-light-gray);
}

.header-nav .menu li {
  margin-right: 0;
}

.menu a {
  color: var(--color-mpr-charcoal);
}

.menu a, .menu button {
  width: 100%;
  padding: 0.875rem;
  padding-left: 1.5rem;
  border: 1px var(--color-mpr-light-gray) solid;
  border-width: 0 0 1px 0;
  border-radius: 0;
  justify-content: flex-start;
  gap: .25rem;
  font-weight: 400;
}

.menu li:last-child a, .menu li:last-child button {
  border-bottom: none;
}

.menu-large a, .menu-large button {
  border: none;
}

.menu-user {
  right: 0;
  left: unset;
}

.menu-user a,
.menu-user .header-user {
  justify-content: center;
}

.menu-user li  {
  margin-right: unset;
}

.menu a:hover, .menu a:focus, .menu button:hover, .menu button:focus {
  color: var(--color-mpr-button-blue-dark);
  background-color: var(--color-bg-gray);
}

.menu button svg {
  transition: all 0.2s;
}

.menu button:focus svg, .menu button:hover svg
  {
  stroke: #fff;
}

.menu .menu-item-higlighted {
  background-color: var(--color-mpr-button-blue);
  color: var(--color-white);
}

.header-nav .menu-item-higlighted:hover, .header-nav .menu-item-higlighted:focus {
  background-color: var(--color-mpr-button-blue-dark);
  color: var(--color-white);
}

.menu .menu-item-header {
  background-color: var(--color-mpr-yellow);
  font-weight: 600;
}

.menu .menu-item-header:hover, .menu .menu-item-header:focus {
  background-color: var(--color-mpr-yellow);
  color: var(--color-mpr-charcoal);
}

.menu .menu-item-header:hover svg, .menu .menu-item-header:focus svg {
  stroke: var(--color-mpr-charcoal);
}

@media (min-width: 65em) {
  .menu {
    overflow: hidden;
    width: auto;
  }

  .menu-large {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.25rem;
    padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    width: auto;
  }

  .menu-label {
    color: var(--color-mpr-dark-gray);
    background-color: transparent;
  }

  .menu a, .menu button {
    width: 14.5rem;
  }

  .menu-user a, .menu-user button {
    width: 8.5rem;
    padding-left: 1rem;
  }
}
