button {
  background-color: transparent;
  border-width: 0;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn, .apm-style-box.button-link a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  padding: 1.25rem 1.875rem;
  border: 1px solid currentColor;
  background: transparent;
  border-radius: .2rem;
  transition: all 0.2s;
  gap: .5rem;
  text-wrap: nowrap;
}

.btn:focus {
  box-shadow: 0 0 0 .2rem var(--color-mpr-blue-trans);
}

.btn-primary, .apm-style-box.button-link a {
  border: 1px solid var(--color-mpr-button-blue);
  background: var(--color-mpr-button-blue);
  color: var(--color-white);
}

.btn-white {
  background: var(--color-white);
  color: var(--color-mpr-button-blue);
  border: 1px var(--color-white) solid;
}

.btn:hover, .btn:focus, .apm-style-box.button-link a:hover, .apm-style-box.button-link a:focus {
  border-color: var(--color-mpr-button-blue-dark);
  background: var(--color-mpr-button-blue-dark);
  color: var(--color-white);
}

.btn:hover svg, .btn:focus svg {
  fill: var(--color-white);
}

.btn:hover .stroke-icon, .btn:focus .stroke-icon {
  fill: transparent;
  stroke: var(--color-white);
}

.btn:disabled {
  border-color: var(--color-mpr-lightgray);
  color: var(--color-mpr-charcoal);
  background: var(--color-mpr-lightgray);
  cursor: not-allowed;
  opacity: .75;
}

.btn-wide {
  width: 20rem;
  max-width: 100%;
}

.btn-full-width {
  display: flex;
  width: 100%;
  padding-inline: 0;
}

.btn-round {
  border-width: 0;
  padding: 0;
  text-transform: none;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  text-align: left;
}

button.btn-round:hover, button.btn-round:focus {
  background-color: inherit;
  color: inherit;
}

.btn-round:hover .btn-round-inner, .btn-round:focus .btn-round-inner {
  background-color: var(--color-mpr-charcoal);
}

.btn-round:hover .btn-round-inner svg, .btn-round:focus .btn-round-inner svg {
  fill: var(--color-white);
}

.btn-round-inner {
  transition: all .15s;
  background-color: var(--color-bg-gray);
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 100%;
  padding: .25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-save {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  transition: all .15s;
}

.btn-save:hover .btn-round-inner svg, .btn-save:focus .btn-round-inner svg {
  stroke: var(--color-white);
  fill: var(--color-white);
}

.btn-save:not(.btn-round) {
  padding: .25rem;
  border-radius: 3px;
}

.btn-save:not(.btn-round):hover, .btn-save:not(.btn-round):focus {
  background-color: var(--color-bg-gray);
}

.btn-save.favorite svg {
  fill: var(--color-mpr-dark-gray);
}

.btn-round-inner-sm,
.btn-round:hover .btn-round-inner-sm, .btn-round:focus .btn-round-inner-sm {
  width: auto;
  height: auto;
  background-color: transparent;
}

.btn-round:hover .btn-round-inner-sm svg, .btn-round:focus .btn-round-inner-sm svg {
  fill: var(--color-mpr-charcoal);
}

.btn-round-inner-dark {
  background-color: var(--color-mpr-dark-gray);
}

.btn-round-inner-dark svg {
  fill: var(--color-white);
}

.btn-round-inner-play {
  transition: all 0.2s;
  background-color: var(--color-mpr-green-med);
  padding-left: .4rem;
}

.btn-round:active .btn-round-inner-play,
.btn-round:focus .btn-round-inner-play {
  background-color: var(--color-mpr-green-dark);
}
.btn-round:hover .btn-round-inner-play {
  background-color: var(--color-mpr-green-hover);
}

.btn-round-inner-pause {
  transition: background-color 0.2s;
  background-color: var(--color-mpr-red);
}

.btn-round:active .btn-round-inner-pause, 
.btn-round:focus .btn-round-inner-pause {
  background-color: var(--color-mpr-red-dark);
}
.btn-round:hover .btn-round-inner-pause {
  background-color: var(--color-mpr-red-hover);
}

.btn-audio-sm {
  gap: .25rem;
}

.btn-audio-sm svg {
  display: block;
  width: 1.1rem;
  height: 1.1rem;
  fill: var(--color-mpr-green-med);
  transition: fill 0.2s;
}

.btn-audio-sm:active svg,
.btn-audio-sm:focus svg {
  fill: var(--color-mpr-green-dark);
}
.btn-audio-sm:hover svg {
  fill: var(--color-mpr-green-hover);
}

.btn-audio-sm.playing svg {
  fill: var(--color-mpr-red);
}

.btn-audio-sm.playing:active svg,
.btn-audio-sm.playing:focus svg {
  fill: var(--color-mpr-red-dark);
}
.btn-audio-sm.playing:hover svg {
  fill: var(--color-mpr-red-hover);
}

.icon-small {
  max-width: 1rem;
}

.btn-slim {
  padding: 0.625rem 0.5rem;
}

.btn-semi-slim {
  padding: 0.75rem 1rem;
  min-width: 6rem;
}

.btn-pagination {
  padding: 0.5rem 0.625rem;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.btn-pagination svg {
  max-height: .33rem;
  margin-top: .1rem;
}

.no-text-transform {
  text-transform: none;
}

.small-gap {
  gap: 0.4rem;
}

@media (max-width: 48em) {
  .btn-wide {
    width: 100%;
  }

  .btn-slim {
    padding: 0.5rem;
  }
}
