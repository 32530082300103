footer {
  /* temporary until the player is reworked */
  margin-bottom: 74px;
}

.footer {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: var(--color-mpr-charcoal);
  padding-top: 5rem;
  padding-bottom: 10rem;
  background-image:
    linear-gradient(
      -2.5deg,
      transparent 2rem, var(--color-mpr-red) 2.1rem,
      var(--color-mpr-red) 5rem, transparent 5.1rem
    ),
    linear-gradient(
      183deg, 
      var(--color-mpr-green) 3rem, transparent 3.1rem
    ),
    linear-gradient(
      1deg,
      var(--color-mpr-blue) 2rem, transparent 2.1rem,
      transparent 3rem, var(--color-mpr-yellow) 3.1rem,
      var(--color-mpr-yellow) 6rem, transparent 6.1rem
    );
}

.footer-contents {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.875rem;
  column-gap: var(--gutter);
  grid-template:
    "logo"   auto
    "links"  auto
    "apps"   auto
    "social" auto
    "copyrt" auto
    / 1fr;
}

.footer-logo-donate {
  grid-area: logo;
}

.footer-logo {
  margin-bottom: var(--line-height-md);
}
.footer-logo img {
  height: var(--font-size-3x);
  width: auto;
}

.footer-donate .btn {
  text-decoration: none;
}

.footer-links {
  grid-area: links;
}

.footer-link a {
  display: block;
  width: 100%;
  padding-block: 0.25rem;
}

.footer a,
.footer .link {
  color: #fff;
  text-decoration: underline;
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
}
.footer a:hover, .footer a:focus,
.footer .link:hover,
.footer .link:focus {
  color: #fff;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
}

.footer svg {
  fill: currentColor;
}

.footer-apps {
  grid-area: apps;
}

.footer-apps li {
  margin-top: 0.5rem;
}

.footer-app-link-img {
  height: 2.5rem;
  width: auto;
}

@supports (mix-blend-mode: screen) {
  /* If the browser supports it: 
  /* desaturate the app store links & match the background color */
  .footer-app-link a {
    filter: grayscale(100%);
    background-color: var(--color-mpr-charcoal);
    mix-blend-mode: screen;
  }
  /* but show full-color on focus */
  .footer-app-link a:active,
  .footer-app-link a:focus,
  .footer-app-link a:hover {
    filter: none;
    background-color: transparent;
    mix-blend-mode: normal;
  }
}

.footer-social {
  grid-area: social;
}

.footer-social h3 {
  margin-bottom: 0.5rem;
}

.footer-social-links {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.footer-social-links .icon {
  height: var(--font-size-xl);
  width: var(--font-size-xl);
  transition: all 0.1s;
}
.footer-social-links .icon + span {
  display: none;
}
.footer-social-links .icon.icon-empty {
  display: none;
}
.footer-social-links .icon.icon-empty + span {
  display: flex;
}

.footer-social-links a:active .icon,
.footer-social-links a:focus .icon,
.footer-social-links a:hover .icon {
  color: var(--color-mpr-light-gray);
  transform: scale(1.05);
}

.footer-copyright {
  grid-area: copyrt;
}

@media screen and (min-width: 44em) {
  .footer-contents {
    grid-template:
      "logo   links " auto
      "apps   links " auto
      "social links " 1fr
      "copyrt copyrt" auto
      / 1fr 1fr;
  }
}

@media screen and (min-width: 66em) {
  .footer-contents {
    grid-template:
      "logo   links  links " auto
      "apps   links  links " auto
      "social links  links " 1fr
      "copyrt copyrt copyrt" auto
      / 1fr 1fr 1fr;
  }

  .footer-link-list {
    columns: 2;
    column-gap: var(--gutter);
  }
  .footer-link {
    break-inside: avoid;
  }
}

@media screen and (min-width: 72em) {
  .footer-contents {
    grid-template:
      "logo   links  links  apps  " auto
      "logo   links  links  social" 1fr
      "copyrt copyrt copyrt copyrt" auto
      / 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 88em) {
  .footer-contents {
    grid-template:
      "logo   links  links  apps  social" 1fr
      "copyrt links  links  apps  social" auto
      / auto 1fr 1fr 1fr auto;
  }
}

