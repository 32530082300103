body {
  margin: 0;
  padding: 0;
  scrollbar-gutter: stable;
  --gutter: clamp(1.125rem, 0.5rem + 2.5vw, 2.5rem);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
}

::selection {
  color: #000;
  background: rgba(148, 196, 217, 0.75);
}

.main {
  overflow-x: hidden;
}

.wrapper {
  padding-inline: var(--gutter);
}

@media (max-width: 40rem) {
  .wrapper-disable-mobile {
    padding-inline: 0;
  }
}

.container {
  width: 100%;
  max-width: 80rem;
  margin-inline: auto;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

figure,
.figure {
  max-width: 100%;
  margin: calc(1em + 0.125rem) auto;
}

.figure > * {
  display: block;
}
.figure > img,
.figure > iframe,
.figure > video,
.figure > canvas {
  display: block;
  width: 100%;
}

.figure.quarter,
.figure-quarter {
  float: left;
  width: calc(50% - 0.625rem);
  margin-left: 0;
  margin-right: 1.25rem;
}
.figure.quarter + *,
.figure-quarter + * {
  margin-top: calc(1em + 0.125rem);
}
.figure-right.figure-quarter,
.figure-right.quarter,
.figure.align-right.figure-quarter,
.figure.align-right.quarter {
  float: right;
  margin-left: 1.25rem;
  margin-right: 0;
}

.story-content ul,
.story-content ol {
  margin: var(--line-height-lg) auto;
  width: min(40rem, 100%);
}

.story-content .content li {
  position: relative;
  padding-left: 1.25rem;
  margin-block: 0.5rem;
  margin-left: 0;
}

.story-content .content li::before {
  display: inline-block;
  content: '◥';
  color: var(--color-mpr-yellow);
  transform: scale(0.75);
  position: absolute;
  left: 0;
  top: 0;
}

.story-content .pagination li::before {
  display: none;
}

.story-content li p {
  margin-block: 0.5rem;
  margin-left: 0;
  max-width: auto;
}

iframe[src*="player.pbs"] {
  width: 100%;
  aspect-ratio: 16/11;
  height: auto;
}

@media (min-width: 60em) {
  iframe[src*="player.pbs"] {
    aspect-ratio: 16/10;
  }
}

iframe[src*="hearken"] {
  width: 50rem;
  max-width: 100%;
  margin: 0 auto;
}

iframe[src*="features.mpr"] {
  width: 100%;
  margin-block: 0.5rem;
}

.spinner {
  position: relative;
  height: 100%;
  margin: 90px 0;
  min-height: 30px;
  width: 100%;
}
.spinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #4298b5;
  border-bottom-color: #4298b5;
  -webkit-animation: spinner 1s linear infinite;
          animation: spinner 1s linear infinite;
}
.spinner-white::before {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@media (min-width: 48em) {
  .figure.half,
  .figure-half {
    float: left;
    width: calc(50% - 0.625rem);
    margin-left: 0;
    margin-right: 1.25rem;
  }
  .figure-half + *,
  .figure-half + * {
    margin-top: calc(1em + 0.125rem);
  }
  
  .figure.quarter,
  .figure-quarter {
    width: calc(33% - 0.417rem);
  }
  .figure-right,
  .figure.align-right {
    float: right;
    margin-left: 1.25rem;
    margin-right: 0;
  }
}

@media (min-width: 60em) {
  .figure.quarter,
  .figure-quarter {
    width: calc(25% - 0.313rem);
  }
}

* + .figure_caption,
.figure_caption + * {
  margin: 0.625rem 0;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.figure_credit {
  display: inline;
  text-align: left;
  color: var(--color-mpr-charcoal-trans);
}
.figure_credit::before {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  display: inline-block;
  margin-right: 0.375em;
  background: linear-gradient(135deg, transparent 50%, var(--color-mpr-yellow) 50%);
}
.figure_text {
  display: inline;
  padding-right: 0.5em;
}

.main {
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
}

.sidebar > * {
  max-width: 30rem;
  margin-inline: auto;
}

.collection_items {
  position: relative;
}

.sidebar .section-sm {
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.sidebar .section-sm .hList {
  padding: 0.75rem;
}

.hList > * {
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
  list-style: none;
}
.hList > *:last-child {
  margin-right: 0;
}

.hList-compressed > * {
  margin-right: 1px;
  margin-bottom: 0;
}

.hList-lg > * {
  margin-right: 1.25rem;
  margin-bottom: 0;
}

.hList-oneLine > * {
  margin-bottom: 0;
}

.ad_text {
  margin-bottom: .5rem;
}

.ad_textWrapper {
  padding: 1rem;
  background-color: #fff;
  border-radius: 3px;
  margin: 0 auto;
}

.premium-underwriting {
  width: 320px;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0 0 .5rem;
}
.premium-underwriting:not(:has(iframe)) {
  display: none;
}

@media (min-width: 48em) {
  .main:not(.main-home) {
    padding: var(--gutter);
    padding-top: calc(0.5 * var(--gutter));
  }

  .content-grid-sidebar {
    margin-top: 3.3rem;
  }

  .story-content-grid .content-grid-sidebar {
    margin-top: 0;
  }

  .section-sm {
    margin-bottom: 1.25rem;
  }
}

/* prevent mis-served ad sizes from breaking layout */
@media screen and (max-width: 1023px) {
  .ad_slot {
    max-width: calc(100vw - 2 * var(--gutter));
    overflow-x: hidden;
    margin-inline: auto;
  }
}
